import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { createBrowserHistory } from 'history';
import { configureStore } from './app/store';
import { Provider } from 'react-redux';
import App from './app';
import sagas from './app/sagas';
import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/browser';
import { ThemeProvider } from '@trustpilot/businessapp-patternlibrary';
import { BrowserRouter } from "react-router-dom";

Sentry.init({
  dsn: 'https://4cad19f19c6745e292cf9fe374680ae6@o211375.ingest.sentry.io/1731860',
  release: 'businessauthentication-app@1.719.0',
  environment: 'Production',
  integrations: [new BrowserTracing()],
});

// prepare store
const history = createBrowserHistory({});

const store = configureStore(history, sagas);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ThemeProvider theme="default">
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </ThemeProvider>,
);
